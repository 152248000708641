import { blue, gold } from "@ant-design/colors"
import {
  Breadcrumb,
  Button,
  Card,
  Icon,
  Input,
  message,
  Popconfirm,
  Table,
} from "antd"
import { gql } from "apollo-boost"
import { Link, navigate } from "gatsby"
import qs from "query-string"
import React from "react"
import { Mutation, Query } from "react-apollo"
import Highlighter from "react-highlight-words"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const { Column } = Table
const pageSize = 10

const DELETE_ITEM = gql`
  mutation deleteItem($id: Int) {
    update_mbt_item_categories(
      where: { id: { _eq: $id }, deleted_at: { _is_null: true } }
      _set: { deleted_at: "now()" }
    ) {
      affected_rows
    }
  }
`

const LIST_ITEM_CATEGORIES = gql`
  query listItemCateogries($limit: Int, $offset: Int, $name: String) {
    mbt_item_categories_aggregate(where: { name: { _ilike: $name } }) {
      aggregate {
        count
      }
    }
    mbt_item_categories(
      limit: $limit
      offset: $offset
      order_by: { name: asc }
      where: { name: { _ilike: $name } }
    ) {
      id
      name
    }
  }
`

export default ({ location }) => {
  const { name, page } = qs.parse(location.search)
  const currentPage = parseInt(page) || 1
  let filterInput = null
  return (
    <Layout>
      <SEO title="Item Category" />
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to="/">
            <Icon type="home" />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Item Category</Breadcrumb.Item>
      </Breadcrumb>
      <Query
        fetchPolicy="cache-and-network"
        query={LIST_ITEM_CATEGORIES}
        variables={{
          limit: pageSize,
          offset: (currentPage - 1) * pageSize,
          name: name && `%${name.replace(/([_%])/g, "\\$1")}%`,
        }}
      >
        {({ data = {}, loading, refetch }) => (
          <Card>
            <div style={{ marginBottom: 16 }}>
              <Button
                type="primary"
                icon="plus"
                onClick={() => navigate("/item-category/new/")}
              >
                New
              </Button>{" "}
              <Button icon="sync" loading={loading} onClick={() => refetch()}>
                Refresh
              </Button>
            </div>
            <Table
              bordered
              dataSource={data.mbt_item_categories}
              loading={loading}
              pagination={{
                current: currentPage,
                disabled: loading,
                pageSize,
                total: data.mbt_item_categories_aggregate
                  ? data.mbt_item_categories_aggregate.aggregate.count
                  : 0,
              }}
              rowKey="id"
              size="middle"
              onChange={(pagination, filters) =>
                navigate(
                  `/item-category/?${qs.stringify({
                    page: pagination.current,
                    ...filters,
                  })}`
                )
              }
            >
              <Column
                title="Name"
                dataIndex="name"
                filterDropdown={({
                  selectedKeys,
                  setSelectedKeys,
                  clearFilters,
                  confirm,
                }) => (
                  <div style={{ padding: 8 }}>
                    <Input
                      ref={node => {
                        filterInput = node
                      }}
                      placeholder="Search"
                      value={selectedKeys[0]}
                      onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                      }
                      onPressEnter={() => confirm()}
                      style={{
                        width: 188,
                        marginBottom: 8,
                        display: "block",
                      }}
                    />
                    <Button
                      type="primary"
                      onClick={() => confirm()}
                      icon="search"
                      size="small"
                      style={{ width: 90, marginRight: 8 }}
                    >
                      Search
                    </Button>
                    <Button
                      onClick={() => clearFilters()}
                      size="small"
                      style={{ width: 90 }}
                    >
                      Reset
                    </Button>
                  </div>
                )}
                filterIcon={filtered => (
                  <Icon
                    type="search"
                    style={{ color: filtered ? blue.primary : undefined }}
                  />
                )}
                filteredValue={name ? [name] : []}
                render={text => (
                  <Highlighter
                    highlightStyle={{ backgroundColor: gold[3], padding: 0 }}
                    searchWords={[name]}
                    autoEscape
                    textToHighlight={text}
                  />
                )}
                onFilterDropdownVisibleChange={visible => {
                  if (visible) {
                    setTimeout(() => filterInput.select())
                  }
                }}
              />
              <Column
                title="Action"
                width={85}
                render={(text, { id }) => (
                  <React.Fragment>
                    <Button
                      disabled={loading}
                      icon="edit"
                      onClick={() => navigate(`/item-category/edit?id=${id}`)}
                    />{" "}
                    <Mutation
                      mutation={DELETE_ITEM}
                      update={(
                        cache,
                        { data: { update_mbt_item_categories } }
                      ) => {
                        if (update_mbt_item_categories.affected_rows) {
                          const { mbt_item_categories } = cache.readQuery({
                            query: LIST_ITEM_CATEGORIES,
                          })
                          cache.writeQuery({
                            query: LIST_ITEM_CATEGORIES,
                            data: {
                              mbt_item_categories: mbt_item_categories.filter(
                                item_category => id !== item_category.id
                              ),
                            },
                          })
                        }
                      }}
                    >
                      {(deleteItem, { loading: deleteLoading }) => (
                        <Popconfirm
                          disabled={loading}
                          title={`Are you sure?`}
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() =>
                            message.info("Delete is temporarily disabled")
                          }
                        >
                          <Button
                            disabled={loading}
                            icon="delete"
                            loading={deleteLoading}
                            type="danger"
                          />
                        </Popconfirm>
                      )}
                    </Mutation>
                  </React.Fragment>
                )}
              />
            </Table>
          </Card>
        )}
      </Query>
    </Layout>
  )
}
